@media (min-width: 992px){
    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

@media print {
    .navbar {
        display: none;
    }
    .component-for-print {
        display: none;
    }
    .footer {
        display: none;
    }
    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        vertical-align: middle;
        border-color: #2e3134;
    }

    .table-sm-custom {
        padding: 0rem 0rem;
        border-color: #2e3134;
    }

    .table-border-td{
        border-color: #2e3134;
    }

    .no-print-component {
        display: none;
    }

    .print-component {
        font-size: 12px;
        padding: 0rem 0rem;
    }
    .cell_not_visible {
        border-color: #2e3134;
    }
}

.all-icons [class^="pe-"]{
    font-size: 40px;
}
.all-icons input{
    border: 0;
}
.all-icons .font-icon-detail{
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-container a{
    padding: 5px 0;
    display: block;
    color: #666666;
}
.all-icons .font-icon-detail input{
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}
.all-icons h5{
    margin-top: 60px;
}
.all-icons > div > .row:first-child h5{
    margin-top: 15px;
}

.places-buttons .btn{
    margin-bottom: 30px
}

.buttons-with-margin .btn{
    margin: 5px;
}

.buttons-with-margin .btn-group .btn{
    margin: 5px 0px 0px 0px;
}

.animated .img-rounded{
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 0 auto;
    display: block;
}
.animated .img-rounded img{
    width: 100%;
}
.heart {
    color: #EB5E28;
    animation: heathing 1s ease infinite;
}

@keyframes heathing{
    0% {
        transform: scale(0.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(0.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(0.75);
    }
}

.sidebar-item {
    width: 100%
}

.buttons-with-all-width {
    width: 100%;
}

.card-body {
    width: 100%;
}

.text-overflow {
    max-width: 300px;
    white-space: nowrap;
    overflow: scroll;
}

.lidercamargo-background {
    background: #b2c257;
    color: white;
}

.table-sm-custom > :not(caption) > * > * {
    padding: 0rem 0rem;
}

.table-sm-custom {
    padding: 0rem 0rem;
}

.cell_not_visible {
    display: none;
}